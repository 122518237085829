$color-red: #ff0000;

.appointment-booking-review__account-signin,
.appointment-booking-review__account-signup-head {
  display: none !important;
}

.appt-book {
  #appointment-book-sections {
    label {
      &.error {
        color: $color-red;
      }
    }
    .appointment-booking-review__error-messages .error-message,
    .appointment-booking-review__error-messages .error-message a {
      color: $color-red;
    }
  }
}
